"use strict";

const Analytics = require('./analytics');

document.addEventListener('DOMContentLoaded', () => {
  // Initialize analytics
  Analytics.init();
  // Handle header visibility when modals are shown/hidden
  const modals = document.querySelectorAll('.modal');
  const header = document.querySelector('#header');

  modals.forEach(modal => {
    modal.addEventListener('show.bs.modal', () => {
      header.classList.add('modal-open');
    });
    modal.addEventListener('hidden.bs.modal', () => {
      header.classList.remove('modal-open');
    });
  });

  // Helper functions
  const select = (el, all = false) => {
    el = el.trim();
    if (all) {
      return [...document.querySelectorAll(el)];
    }
    return document.querySelector(el);
  };

  const on = (type, el, listener, all = false) => {
    let selectEl = select(el, all);
    if (selectEl) {
      if (all) {
        selectEl.forEach(e => e.addEventListener(type, listener));
      } else {
        selectEl.addEventListener(type, listener);
      }
    }
  };

  const onscroll = (el, listener) => {
    el.addEventListener('scroll', listener);
  };

  // Navbar links active state on scroll
  let navbarlinks = select('#navmenu .scrollto', true);
  const navbarlinksActive = () => {
    let position = window.scrollY + 200;
    navbarlinks.forEach(navbarlink => {
      if (!navbarlink.hash) return;
      let section = select(navbarlink.hash);
      if (!section) return;
      if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
        navbarlink.classList.add('active');
      } else {
        navbarlink.classList.remove('active');
      }
    });
  };

  window.addEventListener('load', navbarlinksActive);
  onscroll(document, navbarlinksActive);

  // Scrolls to an element with header offset
  const scrollto = (el) => {
    let header = select('#header');
    let offset = header.offsetHeight;
    let elementPos = select(el).offsetTop;
    window.scrollTo({
      top: elementPos - offset,
      behavior: 'smooth'
    });
  };

  // Header fixed top on scroll
  let selectHeader = select('#header');
  if (selectHeader) {
    const headerScrolled = () => {
      if (window.scrollY > 100) {
        selectHeader.classList.add('header-scrolled');
      } else {
        selectHeader.classList.remove('header-scrolled');
      }
    };
    window.addEventListener('load', headerScrolled);
    onscroll(document, headerScrolled);
  }

  // Back to top button
  let backtotop = select('.back-to-top');
  if (backtotop) {
    const toggleBacktotop = () => {
      if (window.scrollY > 100) {
        backtotop.classList.add('active');
      } else {
        backtotop.classList.remove('active');
      }
    };
    window.addEventListener('load', toggleBacktotop);
    onscroll(document, toggleBacktotop);
  }

  // Mobile nav toggle
  const toggleMobileNav = (show = null) => {
    const navmenu = select('#navmenu');
    const toggle = select('.mobile-nav-toggle');
    const menuIcon = toggle.querySelector('.menu-icon');
    const closeIcon = toggle.querySelector('.close-icon');
    
    // If show is null, toggle based on current state
    const shouldShow = show === null ? !navmenu.classList.contains('active') : show;
    
    // Update menu visibility
    navmenu.classList.toggle('active', shouldShow);
    toggle.classList.toggle('active', shouldShow);
    
    // Update icons
    menuIcon.classList.toggle('d-none', shouldShow);
    closeIcon.classList.toggle('d-none', !shouldShow);
    
    // Update ARIA attributes
    toggle.setAttribute('aria-expanded', shouldShow);
    navmenu.setAttribute('aria-hidden', !shouldShow);
    
    // Prevent body scroll when menu is open
    document.body.style.overflow = shouldShow ? 'hidden' : '';
  };

  // Mobile nav toggle click handler
  on('click', '.mobile-nav-toggle', function(e) {
    e.stopPropagation();
    toggleMobileNav();
  });

  // Close mobile nav when clicking outside
  document.addEventListener('click', function(e) {
    const navmenu = select('#navmenu');
    const toggle = select('.mobile-nav-toggle');
    
    if (navmenu.classList.contains('active') && 
        !navmenu.contains(e.target) && 
        !toggle.contains(e.target)) {
      toggleMobileNav(false);
    }
  });

  // Close mobile nav when pressing escape
  document.addEventListener('keydown', function(e) {
    if (e.key === 'Escape' && select('#navmenu').classList.contains('active')) {
      toggleMobileNav(false);
    }
  });

  // Mobile nav dropdowns activate
  on('click', '.navmenu .dropdown > a', function(e) {
    if (select('#navmenu').classList.contains('active')) {
      e.preventDefault();
      this.nextElementSibling.classList.toggle('dropdown-active');
    }
  }, true);

  // Scroll with offset on links with a class name .scrollto
  on('click', '.scrollto', function(e) {
    if (select(this.hash)) {
      e.preventDefault();
      let navmenu = select('#navmenu');
      if (navmenu.classList.contains('active')) {
        navmenu.classList.remove('active');
        select('.mobile-nav-toggle').classList.remove('active');
      }
      scrollto(this.hash);
    }
  }, true);

  // Initialize AOS
  AOS.init({
    duration: 600,
    easing: 'ease-in-out',
    once: true,
    mirror: false
  });

  // Scroll with offset on page load with hash links in the url
  window.addEventListener('load', () => {
    if (window.location.hash) {
      if (select(window.location.hash)) {
        scrollto(window.location.hash);
      }
    }
  });
});
