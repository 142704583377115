async function initializeCard(payments) {
  const card = await payments.card();
  await card.attach('#square-card-container');
  return card;
}

// Call this function to initialize the payment form
async function initializeForm() {
  // Only proceed with Square initialization if we're on a checkout page
  const cardContainer = document.getElementById('square-card-container');
  if (!cardContainer) {
    return;
  }

  if (!window.Square) {
    console.error('Square.js failed to load properly');
    return;
  }

  const appId = window.squareApplicationId;
  const locationId = window.squareLocationId;

  if (!appId || !locationId) {
    console.error('Square application ID or location ID not provided');
    return;
  }

  const payments = window.Square.payments(appId, locationId);
  let card;

  try {
    card = await initializeCard(payments);
  } catch (e) {
    console.error('Initializing Card failed', e);
    return;
  }

  async function handlePaymentMethodSubmission(event, paymentMethod) {
    event.preventDefault();

    try {
      // disable the submit button as we await tokenization and make a payment request.
      cardButton.disabled = true;
      const token = await tokenize(paymentMethod);
      const paymentResults = await createPayment(token);
      displayPaymentResults('SUCCESS');

      console.debug('Payment Success', paymentResults);
    } catch (e) {
      cardButton.disabled = false;
      displayPaymentResults('FAILURE');
      console.error(e.message);
    }
  }

  const form = document.getElementById('checkoutForm');
  const cardButton = document.getElementById('submitCheckout');

  form.addEventListener('submit', async function (event) {
    event.preventDefault();
    await handlePaymentMethodSubmission(event, card);
  });
}

// This function tokenizes a payment method.
async function tokenize(paymentMethod) {
  const tokenResult = await paymentMethod.tokenize();
  if (tokenResult.status === 'OK') {
    return tokenResult.token;
  } else {
    let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
    if (tokenResult.errors) {
      errorMessage += ` and errors: ${JSON.stringify(
        tokenResult.errors
      )}`;
    }

    throw new Error(errorMessage);
  }
}

// This function handles the creation of a payment
async function createPayment(token) {
  const form = document.getElementById('checkoutForm');
  const formData = new FormData(form);
  
  const body = JSON.stringify({
    sourceId: token,
    amount: document.getElementById('totalAmount').textContent.replace('$', ''),
    customerData: {
      firstName: formData.get('firstName'),
      lastName: formData.get('lastName'),
      email: formData.get('email'),
      phone: formData.get('phone'),
      shipping: {
        street: formData.get('street'),
        apt: formData.get('apt'),
        city: formData.get('city'),
        state: formData.get('state'),
        zip: formData.get('zip'),
        instructions: formData.get('instructions')
      }
    }
  });

  const paymentResponse = await fetch('/api/cart/checkout/process-payment', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });

  if (paymentResponse.ok) {
    return paymentResponse.json();
  }

  const errorBody = await paymentResponse.text();
  throw new Error(errorBody);
}

// This function displays the result of the payment request
function displayPaymentResults(status) {
  const resultDiv = document.getElementById('checkoutResult');
  resultDiv.classList.remove('d-none', 'alert-success', 'alert-danger');
  
  if (status === 'SUCCESS') {
    resultDiv.classList.add('alert-success');
    resultDiv.innerHTML = `
      <i class="bi bi-check-circle-fill me-2"></i>
      Payment Successful! Processing your order...
    `;
    // Clear cart and redirect to confirmation page after a short delay
    setTimeout(() => {
      localStorage.removeItem('cart');
      window.location.href = '/order-confirmation';
    }, 2000);
  } else {
    resultDiv.classList.add('alert-danger');
    resultDiv.innerHTML = `
      <i class="bi bi-exclamation-circle-fill me-2"></i>
      Payment Failed. Please try again.
    `;
  }
}

document.addEventListener('DOMContentLoaded', async function () {
  if (document.getElementById('square-card-container')) {
    await initializeForm();
  }
});
