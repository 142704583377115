// Analytics Event Tracking
const Analytics = {
  init: function() {
    // Initialize scroll tracking
    this.initScrollTracking();
    // Initialize form tracking
    this.initFormTracking();
    // Initialize link tracking
    this.initLinkTracking();
    // Initialize modal tracking
    this.initModalTracking();
  },

  initScrollTracking: function() {
    let maxScroll = 0;
    const scrollThresholds = [25, 50, 75, 90];
    
    window.addEventListener('scroll', () => {
      const scrollPercent = Math.round((window.scrollY + window.innerHeight) / document.documentElement.scrollHeight * 100);
      
      if (scrollPercent > maxScroll) {
        maxScroll = scrollPercent;
        const passedThresholds = scrollThresholds.filter(threshold => scrollPercent >= threshold);
        passedThresholds.forEach(threshold => {
          this.trackScroll(threshold);
        });
      }
    });
  },

  initFormTracking: function() {
    document.querySelectorAll('form').forEach(form => {
      form.addEventListener('submit', (e) => {
        const formName = form.getAttribute('id') || form.getAttribute('name') || 'unnamed_form';
        this.trackFormSubmission(formName);
      });
    });
  },

  initLinkTracking: function() {
    document.querySelectorAll('a').forEach(link => {
      link.addEventListener('click', (e) => {
        const href = link.getAttribute('href');
        if (href && href.startsWith('http')) {
          this.trackEvent('external_link_click', {
            url: href,
            text: link.innerText || 'no_text'
          });
        }
      });
    });
  },

  initModalTracking: function() {
    document.querySelectorAll('.modal').forEach(modal => {
      modal.addEventListener('show.bs.modal', () => {
        this.trackEvent('modal_open', {
          modal_id: modal.id || 'unnamed_modal'
        });
      });
    });
  },

  // E-commerce events
  trackPurchase: function(transaction) {
    gtag('event', 'purchase', {
      transaction_id: transaction.id,
      value: transaction.total,
      currency: 'USD',
      items: transaction.items.map(item => ({
        item_id: item.id,
        item_name: item.name,
        price: item.price,
        quantity: item.quantity
      }))
    });
  },

  // Form submission tracking
  trackFormSubmission: function(formName, success = true) {
    gtag('event', 'form_submission', {
      form_name: formName,
      success: success
    });
  },

  // Newsletter subscription
  trackNewsletterSignup: function(source) {
    gtag('event', 'newsletter_signup', {
      signup_source: source
    });
  },

  // Product interactions
  trackProductView: function(product) {
    gtag('event', 'view_item', {
      items: [{
        item_id: product.id,
        item_name: product.name,
        price: product.price
      }]
    });
  },

  trackAddToCart: function(product) {
    gtag('event', 'add_to_cart', {
      items: [{
        item_id: product.id,
        item_name: product.name,
        price: product.price,
        quantity: product.quantity
      }]
    });
  },

  // Service inquiries
  trackServiceInquiry: function(serviceName) {
    gtag('event', 'service_inquiry', {
      service_name: serviceName
    });
  },

  // Page engagement
  trackScroll: function(percentage) {
    gtag('event', 'scroll_depth', {
      depth: percentage
    });
  },

  // Custom event tracking
  trackEvent: function(eventName, params = {}) {
    gtag('event', eventName, params);
  }
};

module.exports = Analytics;
