document.addEventListener('DOMContentLoaded', () => {
  const consultationModal = document.getElementById('consultation-modal');
  const consultationForm = document.getElementById('consultationForm');
  const submitButton = document.getElementById('submitConsultation');
  const submitSpinner = document.getElementById('submitSpinner');
  const consultationResult = document.getElementById('consultationResult');

  // Bootstrap modal initialization
  const modal = new bootstrap.Modal(consultationModal);

  // Form validation setup
  const forms = document.querySelectorAll('.needs-validation');
  Array.from(forms).forEach(form => {
    form.addEventListener('submit', event => {
      if (!form.checkValidity()) {
        event.preventDefault();
        event.stopPropagation();
      }
      form.classList.add('was-validated');
    }, false);
  });

  // Reset form when modal is shown
  consultationModal.addEventListener('show.bs.modal', () => {
    consultationForm.classList.remove('was-validated');
    consultationResult.classList.add('d-none');
    consultationForm.reset();
  });

  function setLoadingState(loading) {
    const formInputs = consultationForm.querySelectorAll('input, select, textarea, button');
    formInputs.forEach(input => input.disabled = loading);
    if (loading) {
      submitSpinner.classList.remove('d-none');
      submitButton.querySelector('.button-text').textContent = 'Submitting...';
    } else {
      submitSpinner.classList.add('d-none');
      submitButton.querySelector('.button-text').textContent = 'Request Consultation';
    }
  }

  function showResult(message, isError = false) {
    consultationResult.textContent = message;
    consultationResult.classList.remove('d-none', 'alert-success', 'alert-danger');
    consultationResult.classList.add(isError ? 'alert-danger' : 'alert-success');
  }

  // Handle form submission
  consultationForm.addEventListener('submit', async (e) => {
    e.preventDefault();

    if (!consultationForm.checkValidity()) {
      return;
    }

    // Get selected primary concerns
    const primaryConcerns = Array.from(
      consultationForm.querySelectorAll('input[name="primaryConcerns[]"]:checked')
    ).map(input => input.value);

    if (primaryConcerns.length === 0) {
      showResult('Please select at least one primary concern', true);
      return;
    }

    setLoadingState(true);

    // Create form data object
    const formData = new FormData(consultationForm);
    const requestData = {
      name: formData.get('name'),
      email: formData.get('email'),
      phone: formData.get('phone'),
      location: formData.get('location'),
      operationType: formData.get('operationType'),
      colonyCount: formData.get('colonyCount'),
      primaryConcerns,
      preferredSchedule: formData.get('preferredSchedule'),
      additionalNotes: formData.get('additionalNotes')
    };

    try {
      const response = await fetch('/api/consultations/submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestData)
      });

      const result = await response.json();

      if (result.success) {
        showResult('Thank you! Your consultation request has been submitted. We will contact you shortly to confirm the details.');
        consultationForm.reset();
        consultationForm.classList.remove('was-validated');
        
        // Format estimated response time
        const hours = Math.floor(result.data.estimatedResponseTime / 60);
        const minutes = result.data.estimatedResponseTime % 60;
        const timeString = hours > 0 
          ? `${hours} hour${hours > 1 ? 's' : ''} ${minutes > 0 ? `and ${minutes} minutes` : ''}`
          : `${minutes} minutes`;

        // Add estimated response time to success message
        const estimateMessage = `\n\nEstimated response time: ${timeString}`;
        consultationResult.textContent += estimateMessage;

        // Close modal after delay
        setTimeout(() => {
          modal.hide();
        }, 5000);
      } else {
        showResult(result.message || 'Error submitting consultation request. Please try again.', true);
      }
    } catch (error) {
      console.error('Error submitting consultation request:', error);
      showResult('An error occurred. Please try again later.', true);
    } finally {
      setLoadingState(false);
    }
  });

  // Handle primary concerns validation
  const concernCheckboxes = consultationForm.querySelectorAll('input[name="primaryConcerns[]"]');
  concernCheckboxes.forEach(checkbox => {
    checkbox.addEventListener('change', () => {
      const anyChecked = Array.from(concernCheckboxes).some(cb => cb.checked);
      if (anyChecked) {
        consultationResult.classList.add('d-none');
      }
    });
  });

  // Location input enhancement
  const locationInput = document.getElementById('location');
  if ('geolocation' in navigator) {
    locationInput.addEventListener('focus', () => {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          try {
            const response = await fetch(
              `https://nominatim.openstreetmap.org/reverse?lat=${position.coords.latitude}&lon=${position.coords.longitude}&format=json`
            );
            const data = await response.json();
            if (data.display_name && !locationInput.value) {
              locationInput.value = data.display_name;
            }
          } catch (error) {
            console.warn('Could not get address from coordinates:', error);
          }
        },
        (error) => {
          console.warn('Geolocation error:', error);
        },
        { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }
      );
    });
  }
});
